/* eslint-disable */

import { Api } from '../../axios-config/AxiosConfig';
import { ApiException } from '../ApiException';

interface IGetSecret {
  name: string,
  value: string
}

const getSecret = async (name: string): Promise<{ data: IGetSecret } | ApiException> => {
  try {
    const { data } = await Api.get(`Secret/${name}`);
    return {
      data,
    };
  } catch (error: any) {
    console.error(error);
    return new ApiException(error.data.data.erro);

  }
};

const updateSecret = async (name: string): Promise<void | ApiException> => {
  try {
    await Api.patch(
      `Secret/${name}`,{});
  } catch (error: any) {
    console.error('Erro ao atualizar o secret:', error);

    const message = error?.response?.data?.erro || 'Erro desconhecido';
    return new ApiException(message);
  }
};

export const ClientCredentialsService = {
  getSecret,
  updateSecret
}