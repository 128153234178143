/* eslint-disable */

import React, { memo, useCallback, useState } from "react";
import { ClientCredentialsService } from "../../shared/services/api/client-credentials/ClientCredentialsService";
import { ApiException } from "../../shared/services/api/ApiException";
import { feedback } from "../../shared/services";
import { PageLayout } from "../../shared/layouts";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  TextField,
} from "@mui/material";
import { Section } from "../../shared/components";
import Visibility from "@mui/icons-material/Visibility";
import { useTemPermissao } from "../../shared/contexts/PermissoesContext";

export const DetalheClientCredentials: React.FC<any> = memo(() => {
  const [secret, setSecret] = useState("***************");
  const [isLoading, setIsLoading] = useState(false);

  const permiteVisualizarSecret = useTemPermissao('clientes.geral.client-credentials.consultar');
  const permiteAtualizarSecret = useTemPermissao('login-service.geral.client.alterar-secret');
  const permiteCriarClient = useTemPermissao('login-service.geral.client.incluir');


  const getSecret = useCallback(() => {
    setIsLoading(true);
    if(secret === '***************'){
      ClientCredentialsService.getSecret('secret').then((result) => {
        setIsLoading(false);
        if (result instanceof ApiException) {
          feedback(result.message, "error");
        } else {
          setSecret(result.data.value);
        }
      });
    }else {
      setIsLoading(false);
      setSecret('***************');
    }
  }, [secret]);

  const regerarSecret = useCallback(() => {
    setIsLoading(true);
    ClientCredentialsService.updateSecret('secret').then(
      (result) => {
        setIsLoading(false);
        if (result instanceof ApiException) {
          feedback(result.message, "error");
        } else {
          setSecret('***************');
          feedback("Secret regerado com sucesso!", "success");
        }
      }
    );
  }, []);

  const permiteManipularClientCac = () => {
    if(!permiteAtualizarSecret && !permiteCriarClient) return false;
    return true;

  }

  return (
    <PageLayout title="Client Credentials">
      <Box>
        <Section title="Client CAC" margin>
          <Box display="flex" gap={2} alignItems="center">
            {!isLoading && (
              <TextField
                disabled={permiteVisualizarSecret}
                value={secret}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={getSecret} disabled={permiteVisualizarSecret}>
                      <Visibility />
                    </IconButton>
                  ),
                }}
              />
            )}
            {isLoading && <Skeleton height="60px" width="250px" />}
            {!isLoading && (
              <Button disabled={permiteManipularClientCac()} variant="contained" onClick={regerarSecret}>
                Regerar secret
              </Button>
            )}
            {isLoading && <Skeleton height="60px" width="200px" />}
          </Box>
        </Section>
      </Box>
    </PageLayout>
  );
});
